import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const fetchTokenValidation = async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await axios.get(backendUrl + "/api/v1/auth/verifyJwtToken/" + token);
        setIsAuthenticated(response.data);
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTokenValidation();
  }, [location.pathname]); // Re-run token validation when route changes

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while checking token validity
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
}

export default PrivateRoute;
