import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bgImage from "../assets/chattingbg.jpg";
import "./Login.css";
const LoginPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useNavigate();
  useEffect(() => {
    const fetchTokenValidation = async () => {
    const token=localStorage.getItem('access_token');
    if(token!==null && typeof(token)!=='undefined')
    {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: backendUrl + "/api/v1/auth/verifyJwtToken/"+localStorage.getItem('access_token'),
    };
    axios
      .request(config)
      .then((response) => {
        if(response.data===true)
        {
          getUserInfo();
          history('/Home/Dashboard');
          
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }
  fetchTokenValidation();  
},[])
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        backendUrl+"/api/v1/auth/authenticate",
        { email: username, password: password }
      );
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      await getUserInfo();
      history("/Home/Dashboard");
    } catch (error) {
      setError("Invalid username or password!");
    }
  };
const getUserInfo=async ()=>{
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: backendUrl + "/api/v1/users/getUserInfo",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response.data)
      localStorage.setItem("userDetails",JSON.stringify(response.data))
    })
    .catch((error) => {
      console.log(error);
    })
  }
  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      style={{ backgroundColor: "#f5f8fb" }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            C H A T Y F Y
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item">
                <a
                  className="nav-link mx-2 active"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="/signup">
                  Register Here
                </a>
              </li>
              {/* <li className="nav-item">
            <a className="nav-link mx-2" href="#">Pricing</a>
          </li>
           <li className="nav-item dropdown">
            <a className="nav-link mx-2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Company
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a className="dropdown-item" href="#">Blog</a></li>
              <li><a className="dropdown-item" href="#">About Us</a></li>
              <li><a className="dropdown-item" href="#">Contact us</a></li>
            </ul>
          </li> */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#e3edf9" }}
                >
                  <a
                    href="/"
                    className="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img src={bgImage} width="50" alt="" />
                  </a>
                  <p
                    className="text-center text-bold"
                    style={{ color: "#111c2d", fontWeight: "bold" }}
                  >
                    C H A T Y F Y
                  </p>
                  {error && (
                    <div
                      className="alert alert-danger mt-3 alert-dismissible fade show"
                      role="alert"
                    >
                      {error}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={()=>setError()}
                      ></button>
                    </div>
                  )}
                  <form onSubmit={handleLogin}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Username
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="form-check">
                        <input
                          className="form-check-input primary"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label text-dark"
                          htmlFor="flexCheckChecked"
                        >
                          Remember this Device
                        </label>
                      </div>
                      {/* <div>
                      <a className="text-primary fw-bold" href="./index.html">
                        Forgot Password ?
                      </a>
                      </div> */}
                    </div>
                    <div className="text-center">
                      <button className="btn btn-primary" type="submit">
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
