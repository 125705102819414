import React, { useState } from "react";
import axios from "axios";
import bgImage from "../assets/chattingbg.jpg";

const RegistrationForm = () => {
  const backendUrl=process.env.REACT_APP_BACKEND_URL
  const [formData, setFormData] = useState({
    organizationName: "",
    description: "",
    businessEmail: "",
    mobileNumber: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make API call to submit form data
      await axios.post(backendUrl+"/api/v1/auth/register", formData);
      setSubmitted(true);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      style={{ backgroundColor: "#f5f8fb" }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            C H A T Y F Y
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item">
                <a
                  className="nav-link mx-2 active"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="/">
                  Sign In Here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#e3edf9" }}
                >
                  <a
                    href="./index.html"
                    className="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img src={bgImage} width="50" alt="" />
                  </a>
                  <p
                    className="text-center text-bold"
                    style={{ color: "#111c2d", fontWeight: "bold" }}
                  >
                    C H A T Y F Y
                  </p>
                  {errorMessage && (
                    <div
                      className="alert alert-danger mt-3 alert-dismissible fade show text-center"
                      role="alert"
                    >
                      {errorMessage}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}

                  {!submitted ? (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="organizationName"
                          className="form-label"
                        >
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="organizationName"
                          name="organizationName"
                          value={formData.organizationName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Industry
                        </label>
                        <input
                          className="form-control"
                          id="description"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="businessEmail" className="form-label">
                          Business Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="businessEmail"
                          name="businessEmail"
                          value={formData.businessEmail}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label">
                          Mobile Number
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="mobileNumber"
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div className="alert alert-success">
                      Thanks for registering! We will verify your details and
                      send you an onboarding link. Please keep an eye on the
                      registered email <strong>{formData.businessEmail}</strong>
                      . Check out spam folder as well. It will take 24 to 48
                      hours for verification.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
