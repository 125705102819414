import React from "react";
import './NoPage.css';

const NoPage = () => {
  return (
    <div className="not-found-container">
      <h1>404 Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NoPage;
