import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import logo from "../assets/chattingbg.jpg";
// sidebar nav config
import navigation from "../_nav";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const divStyle = {
    background: "#202531",
    boxShadow: "1px 3px 1px #9E9E9E",
    // eslint-disable-next-line no-dupe-keys
     //background: "-webkit-linear-gradient(to top,#CDC1FF, #E5D9F2)",
     // eslint-disable-next-line no-dupe-keys
     //background: "linear-gradient(to top,#CDC1FF, #E5D9F2)",
  };

  return (
    <CSidebar
    style={divStyle}
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand
        className="d-flex p-2"
        to="/"
        style={{
          borderBottom: "2px solid #ffffff",
        }}
      >
        <CImage
          src={logo}
          className="d-sm-block d-md-none d-lg-block"
          height={40}
        />
        <h5 className="pt-2 px-2">
          CHATYFY
        </h5>
      </CSidebarBrand>

      <CSidebarNav style={divStyle}>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        style={{ backgroundColor: "rgb(27, 66, 79)", borderRadius:"10px"  }}
        className="d-flex"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      /> */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
