import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cibWhatsapp,
  cilSpeedometer,
  cilUser,
  cilChatBubble,
  cilCalendar,
  cilCash,
  cilLoop1,
  cilNotes,
  cilLinkAlt,
} from "@coreui/icons";
import { CNavItem, CNavGroup } from "@coreui/react";
const iconStyle = {
  color: "#fff",
};

const navStyle={
  "paddingLeft":"30px"
}
const _nav = [
  // (ReactSession.get("role")==='Admin'||ReactSession.get("role")==='SuperAdmin')?{
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/Home/Dashboard",
    icon: (
      <CIcon
        style={iconStyle}
        icon={cilSpeedometer}
        customClassName="nav-icon"
      />
    ),
  },
  {
    component: CNavItem,
    name: "Connect Business",
    to: "/Home/EmbeddedSignUp",
    icon: (
      <CIcon style={iconStyle} icon={cibWhatsapp} customClassName="nav-icon" />
    ),
  },
  {
    component: CNavItem,
    name: "Customer Data",
    to: "/Home/CustomerData",
    icon: <CIcon style={iconStyle} icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Message Template",
    to: "/Home/MessageTemplate",
    icon: (
      <CIcon
        style={iconStyle}
        icon={cilChatBubble}
        customClassName="nav-icon"
      />
    ),
  },
  {
    component: CNavGroup,
    name: "Flows",
    icon: (
      <CIcon style={iconStyle} icon={cilNotes} customClassName="nav-icon" />
    ),
    items: [
      {
        component: CNavItem,
        name: "Create Flows",
        to: "/Home/ScheduleFlow",
        icon: <CIcon  style={navStyle} icon={cilLoop1} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "View Flows",
        to: "/Home/ScheduleFlow/AssignedFlows",
        icon: <CIcon style={navStyle} icon={cilLinkAlt} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: "Schedule Messages",
    to: "/Home/Schedules",
    icon: (
      <CIcon style={iconStyle} icon={cilCalendar} customClassName="nav-icon" />
    ),
  },
  {
    component: CNavItem,
    name: "Billing & Payment",
    to: "/Home/Payment",
    icon: <CIcon style={iconStyle} icon={cilCash} customClassName="nav-icon" />,
  },

  // {
  //   component: CNavGroup,
  //   name: 'Patient Data',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Pending Accession',
  //       to: '/Home/Pending',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Accessed',
  //       to: '/Home/Assessed',
  //     },
  //     (ReactSession.get("role")!=='Receptionist')?{
  //       component: CNavItem,
  //       name: 'Load Tray',
  //       to: '/Home/ReadyForTesting',
  //     }:null,
  //     (ReactSession.get("role")!=='Receptionist')?{
  //       component: CNavItem,
  //       name: 'Review Results',
  //       to: '/Home/ReviewResults',
  //     }:null,
  //     (ReactSession.get("role")!=='Receptionist')?{
  //       component: CNavItem,
  //       name: 'Rejected',
  //       to: '/Home/Rejected',
  //     }:null,
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Load Results',
  //   to: '/Home/loadResults',
  //   icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'County Reporting',
  //   to: '/widgets',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'SiteList',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
];

export default _nav;
