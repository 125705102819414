import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import bgImage from "../assets/chattingbg.jpg";

function LoginForm() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { token } = useParams();
  const history = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(
          backendUrl + "/api/v1/auth/verifyToken/" + token
        );
        if (response.data === false) {
          history("/InvalidUrl");
        }
      } catch (error) {
        console.error("Error validating token:", error);
        alert("An error occurred while validating token");
      }
    };
    validateToken();
  }, [token, history, backendUrl]);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "ADMIN",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        backendUrl + "/api/v1/auth/registerAdminUser/" + token,
        formData
      );
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      history("/Home/Dashboard");
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=.*[a-z]).{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <div
      className="page-wrapper"
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      style={{ backgroundColor: "#f5f8fb" }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            C H A T Y F Y
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item">
                <a
                  className="nav-link mx-2 active"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="/signup">
                  Register Here
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#e3edf9" }}
                >
                  <a
                    href="./index.html"
                    className="text-nowrap logo-img text-center d-block py-3 w-100"
                  >
                    <img src={bgImage} width="50" alt="" />
                  </a>
                  <p
                    className="text-center text-bold"
                    style={{ color: "#111c2d", fontWeight: "bold" }}
                  >
                    C H A T Y F Y
                  </p>
                  {errorMessage && (
                    <div
                      className="alert alert-danger  alert-dismissible fade show"
                      role="alert"
                    >
                      {errorMessage}

                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="firstname" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastname" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {formData.password &&
                        !validatePassword(formData.password) && (
                          <div className="text-danger mt-1">
                            Password must have at least 8 characters, one
                            capital letter, and one special character.
                          </div>
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary">
                      SignUp
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
