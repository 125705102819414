import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilFile, cilAccountLogout, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import avatar6 from "./../../assets/images/avatars/8.jpg";
import { useNavigate } from "react-router-dom";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const csvHeaders = () => {
    navigate("/Home/CsvHeaders");
  };

  return (
    <CDropdown style={{ color: "black", display: "flex",paddingBottom:"10px" }}>
      <CDropdownToggle
        caret={true}
        style={{
          backgroundColor: "transparent",
          color: "black",
          border: "none",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        <CAvatar src={avatar6} size="md" />
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            lineHeight: "1.2",
          }}
        >
          <span style={{ fontWeight: "bold", marginBottom: "2px" }}>
            {localStorage.getItem("userDetails") !== null
              ? JSON.parse(localStorage.getItem("userDetails")).name
              : ""}
          </span>
          <span style={{ fontSize: "small", color: "gray" }}>
            {localStorage.getItem("userDetails") !== null
              ? JSON.parse(localStorage.getItem("userDetails")).waBaName
              : ""}
          </span>
        </div>
      </CDropdownToggle>

      <CDropdownMenu
        className="pt-0"
        placement="bottom-end"
        style={{ minWidth: "80px" }}
      >
        <CDropdownHeader
          style={{ color: "black" }}
          className="bg-light fw-semibold py-2"
        >
          Settings
        </CDropdownHeader>
        <CDropdownItem href="#" style={{ color: "black" }}>
          <CIcon icon={cilUser} className="me-2" style={{ color: "black" }} />
          Profile
        </CDropdownItem>
        <CDropdownItem onClick={csvHeaders} style={{ color: "black" }}>
          <CIcon icon={cilFile} className="me-2" />
          Manage Headers
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={logout} style={{ color: "black" }}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
