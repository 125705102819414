import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./Components/Login/Login";
import RegistrationForm from "./Components/Register/Register";
import NoPage from "./Components/NoPageFound/NoPage";
import TokenValidationPage from "./Components/TokenValidation/TokenValidation";
import SignUpForm from "./Components/SignUp/Signup";
import DefaultLayout from "./Components/layout/DefaultLayout";
import "../src/Components/scss/style.scss";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/signup" element={<RegistrationForm />} />
          <Route
          path="/Home/*"
          element={
              <DefaultLayout />
          }
        />
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/adminsignup/:token" element={<SignUpForm />} />
          <Route
            exact
            path="/debugToken/:token"
            element={<TokenValidationPage />}
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
